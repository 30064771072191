import React from 'react';

const Prebiotics = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="49.758"
    height="49.731"
    viewBox="0 0 49.758 49.731"
  >
    <defs>
      <clipPath>
        <rect width="49.758" height="49.731" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M24.911,0A24.866,24.866,0,1,1,0,25.006,24.817,24.817,0,0,1,24.911,0m-.06,47.6A22.774,22.774,0,1,0,2.108,24.884,22.767,22.767,0,0,0,24.851,47.6"
        transform="translate(0 -0.001)"
        fill="currentColor"
      />
      <path
        d="M36.4,149.3a3.48,3.48,0,0,1,2.245,1.031,17.021,17.021,0,0,0,6.159,3.942,3.241,3.241,0,0,1,2.269,2.721,2.185,2.185,0,0,1-1.533,2.482,3.487,3.487,0,0,1-2.438.045,21.168,21.168,0,0,1-7.371-4.345,6.577,6.577,0,0,1-1.805-2.18,3.019,3.019,0,0,1-.152-2.073A2.463,2.463,0,0,1,36.4,149.3"
        transform="translate(-27.443 -121.718)"
        fill="currentColor"
      />
      <path
        d="M133.377,147.533a2.607,2.607,0,0,1-2.447-1.27,2.434,2.434,0,0,1,.288-2.771,4.365,4.365,0,0,1,1.5-1.313,17.35,17.35,0,0,0,5-3.557c.416-.429.786-.91,1.234-1.3a3.1,3.1,0,0,1,2.779-.769,2.333,2.333,0,0,1,1.871,2.788,4.764,4.764,0,0,1-1.323,2.48,21.667,21.667,0,0,1-6,4.746,6.024,6.024,0,0,1-2.9.967"
        transform="translate(-106.489 -111.266)"
        fill="currentColor"
      />
      <path
        d="M110.033,72.934a21.27,21.27,0,0,1,9.075,2.6,3.679,3.679,0,0,1,1.544,1.537,2.427,2.427,0,0,1-2.068,3.7,3.912,3.912,0,0,1-2.224-.446,15.642,15.642,0,0,0-6.819-1.872,3.45,3.45,0,0,1-2.783-1.486,2.291,2.291,0,0,1,.558-3.324,4.077,4.077,0,0,1,2.717-.71"
        transform="translate(-86.643 -59.459)"
        fill="currentColor"
      />
      <path
        d="M79,129.282a25.1,25.1,0,0,1-4.841-.66,3.479,3.479,0,0,1-2.171-1.449,2.347,2.347,0,0,1,.759-3.433,3.62,3.62,0,0,1,2.747-.466,16.354,16.354,0,0,0,7.213,0,3.554,3.554,0,0,1,2.585.379,2.44,2.44,0,0,1,.388,4.12,5.815,5.815,0,0,1-3.159,1.14c-1.166.173-2.346.249-3.52.367"
        transform="translate(-58.322 -100.425)"
        fill="currentColor"
      />
      <path
        d="M108.263,211.675a18.207,18.207,0,0,1-2.44-.366,3,3,0,0,1-1.37-.871c-1.11-1.27-.586-2.572.619-3.4a3.664,3.664,0,0,1,2.473-.516,13.99,13.99,0,0,0,5.953-.931,3.42,3.42,0,0,1,2.165-.139,2.252,2.252,0,0,1,.915,4.049,6.306,6.306,0,0,1-1.8.977,20.836,20.836,0,0,1-6.511,1.2"
        transform="translate(-84.658 -167.398)"
        fill="currentColor"
      />
      <path
        d="M25.3,86.2a2.911,2.911,0,0,1,.668-1.92,18.478,18.478,0,0,1,7.516-5.624,2.974,2.974,0,0,1,2.295.017,2.081,2.081,0,0,1,1.273,2.469,3.077,3.077,0,0,1-1.724,2.154,15.165,15.165,0,0,0-4.037,2.631c-.572.533-1.069,1.145-1.641,1.679a2.872,2.872,0,0,1-2.536.815A1.987,1.987,0,0,1,25.3,86.2"
        transform="translate(-20.624 -63.941)"
        fill="currentColor"
      />
      <path
        d="M95.947,32.472c1.18.109,2.1.157,3.01.289a3.017,3.017,0,0,1,2.126,1.247,2.1,2.1,0,0,1-.343,2.867,3.254,3.254,0,0,1-2.691.8,14.631,14.631,0,0,0-6.014.485,6.272,6.272,0,0,1-1.619.292,2.685,2.685,0,0,1-2.582-1.8,2.462,2.462,0,0,1,1.359-2.685,11.937,11.937,0,0,1,2.9-.978c1.347-.273,2.729-.376,3.851-.52"
        transform="translate(-71.559 -26.473)"
        fill="currentColor"
      />
      <path
        d="M192.072,67.248a6.35,6.35,0,0,1-.085.808,2.074,2.074,0,0,1-1.523,1.737,2.554,2.554,0,0,1-2.642-.922,5.546,5.546,0,0,1-.834-1.469,15.26,15.26,0,0,0-3.522-5.128,2.988,2.988,0,0,1-.944-2.178,2.169,2.169,0,0,1,2.948-2.251,4.388,4.388,0,0,1,1.937,1.281,18.6,18.6,0,0,1,3.35,4.544c.446.872.807,1.789,1.169,2.7a3.83,3.83,0,0,1,.146.876"
        transform="translate(-148.802 -47.065)"
        fill="currentColor"
      />
      <path
        d="M201.349,149.06a5.674,5.674,0,0,1-.743,2.586,17.407,17.407,0,0,1-4.155,5.852,2.941,2.941,0,0,1-1.837.768,2.029,2.029,0,0,1-2.255-2.155,2.891,2.891,0,0,1,.832-2.088,14.745,14.745,0,0,0,3.484-5.417,3.036,3.036,0,0,1,1.6-1.761,2.089,2.089,0,0,1,3.071,1.8c.011.137,0,.276,0,.414"
        transform="translate(-156.822 -119.525)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Prebiotics;
